<template>
  <v-card class="my-5">
    <v-card-title class="row justify-center">
      <span class="headline primary--text">{{ isNew() ? "Создать" : "Редактировать" }}</span>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-text-field v-model="item.name"
                        outlined
                        dense
                        hide-details
                        label="Название"/>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="item.description"
                        outlined
                        dense
                        hide-details
                        label="Детали"/>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="item.notification"
                        outlined
                        dense
                        hide-details
                        label="Уведомление"/>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="item.mass"
                        outlined
                        dense
                        hide-details
                        label="Масса"/>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="item.price"
                        outlined
                        dense
                        hide-details
                        label="Цена"/>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="item.cook_time"
                        outlined
                        dense
                        hide-details
                        label="Время приготовления"/>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="item.start_at"
                        outlined
                        dense
                        hide-details
                        placeholder="00:00"
                        label="Начало"/>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="item.finish_at"
                        outlined
                        dense
                        hide-details
                        placeholder="00:00"
                        label="Завершение"/>
        </v-col>
        <v-col cols="6">
          <v-checkbox v-model="item.discount_disabled"
                      label="Не учитывать в скидках"/>
        </v-col>
        <v-col cols="6">
          <v-checkbox v-model="item.hidden" label="Скрытый"
                      color="primary"/>
        </v-col>
      </v-row>
      <v-card v-if="!isNew()">
        <v-card-title class="row justify-center">
          <span class="headline primary--text">Добавить прикрепленные блюда</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="5">
              <v-select
                  v-model="selectedItem"
                  :items="hiddenList"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  hide-details
                  label="Наименование"
                  persistent-hint
                  return-object
                  single-line/>
            </v-col>
            <v-col cols="5">
              <v-text-field v-model="selectedCount"
                            outlined
                            dense
                            hide-details
                            label="Количество"/>
            </v-col>
            <v-col cols="2">
              <v-btn v-if="selectedItem.id" @click="addChild" icon color="primary">
                <v-icon>mdi-plus-box</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-for="child in item.children" :key="child.id">
            <v-col cols="5">
              {{ child.name }}
            </v-col>
            <v-col cols="5">
              {{ child.count }}
            </v-col>
            <v-btn icon color="red" @click="removeChild(child.id)">
              <v-icon>mdi-minus-circle</v-icon>
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary" outlined @click="close">Отменить</v-btn>
      <v-btn class="mr-2" color="primary" outlined @click="save">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {uuid} from "uuidv4";
import axios from "../../services/axios";

export default {
  data() {
    return {
      item: {
        id: uuid.Nil,
        name: "",
        description: "",
        notification: "",
        mass: "",
        price: "",
        image: "",
        cook_time: 0,
        hidden: false,
        store_id: uuid.Nil,
        categories: [],
        discount_disabled: false
      },
      hiddenList: [],
      selectedItem: {},
      selectedCount: "0",
      children: []
    }
  },

  created() {
    this.loadItem();
    this.loadHidden();
  },

  computed: {
    currentItem() {
      return this.$store.getters["items/item"];
    }
  },

  methods: {
    addChild() {
      const data = {
        parent_id: this.$route.params.id,
        child_id: this.selectedItem.id,
        count: parseInt(this.selectedCount)
      }
      axios({
        url: "/items/child/add",
        method: "POST",
        data: data
      }).then(() => {
        this.loadItem();
      });
    },

    removeChild(childID) {
      const data = {
        parent_id: this.$route.params.id,
        child_id: childID,
      }
      axios({
        url: "/items/child/delete",
        method: "DELETE",
        data: data
      }).then(() => {
        this.loadItem();
      });
    },

    loadHidden() {
      let storeID = this.$route.params.storeID;
      axios({
        url: "/items/hidden/" + storeID,
        method: "GET"
      }).then(resp => {
        this.hiddenList = resp.data;
      });
    },

    isNew() {
      if (!this.item.id) {
        return true
      }
      return !this.item.id === uuid.Nil;
    },

    handleImageUpload(id) {
      let file = this.$refs.image.files[0];
      this.$store.dispatch("items/uploadImage", {id, file});
    },

    loadItem() {
      let payload = {
        store_id: this.$route.params.store_id,
        item_id: this.$route.params.item_id,
      }

      if (payload.item_id) {
        this.$store.dispatch("items/fetchSingle", payload)
            .then(() => {
              this.item = this.currentItem;
            });
      }
    },
    save() {
      this.item.store_id = this.$route.params.storeID;
      this.$store.dispatch("items/save", this.item)
          .then(() => {
            this.loadItem();
            this.close();
          });
    },

    close() {
      this.id = uuid.Nil;
      this.name = "";
      this.description = "";
      this.notification = "";
      this.mass = "";
      this.price = "";
      this.image = "";
      this.cook_time = 0;
      this.hidden = false;
      this.discount_disabled = false;
      this.store_id = uuid.Nil;
      this.categories = [];
      this.$router.go(-1)
    }
  }
}
</script>
